import { NativeHelper } from '@web/data/native.helper';
import { NativeAuthHeaders } from '@web/data/vigilantes.datasource.native-auth';
import { JsonObject } from 'type-fest';
import { usePost, UsePostInput, UsePostState } from './use-post.hook';
import { VigilantesDataSource } from './vigilantes.datasource';
import { MessengerAuthHeaders } from './vigilantes.datasource.messenger-auth';

export function usePostUsingMessenger<Req = JsonObject, Res = JsonObject, RawResponse = JsonObject>(
  input: UsePostInput<Res>
): [(data?: Req, mapper?: (response: RawResponse) => Res) => Promise<Res>, UsePostState<Res>] {
  const authHeader = NativeHelper.isNative ? new NativeAuthHeaders() : new MessengerAuthHeaders();
  console.log('DIARY_DEBUG: use-post-using-messenger.hook.ts ~ line 1 ~ NativeHelper.isNative', NativeHelper.isNative);
  const datasource = new VigilantesDataSource(authHeader);

  return usePost<Req, Res, RawResponse>(input, datasource);
}
