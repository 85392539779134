import UserInfoDataSource from './messenger-user-info.datasource';
import { HttpAuthHeader } from './vigilantes.datasource';

enum MessengerAuthHeadersError {
  InvalidCredentialsError = 'Invalid Messenger Credentials',
}

export class MessengerAuthHeaders implements HttpAuthHeader {
  getAuthHeader = async (): Promise<{ 'x-psid': string; 'x-signed-request': string }> => {
    console.log('DIARY_DEBUG: vigilantes.datasource.messenger-auth.ts ~ line 11 ~ MessengerAuthHeaders ~ getAuthHeader= ~ userInfo');
    const userInfo = await UserInfoDataSource.getUserInfo();
    if (!userInfo.psid || !userInfo.signedRequest) {
      throw new Error(MessengerAuthHeadersError.InvalidCredentialsError);
    }
    return {
      'x-psid': userInfo.psid || '',
      'x-signed-request': userInfo.signedRequest || '',
    };
  };
}
