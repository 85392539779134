import { GatsbyButton } from '@components/atm.button/button.component';
import { toBoolean } from '@global/utils/boolean/to-boolean';
import { SEO } from '@lp-root/src/components/legacy/mol.seo/seo.component';
import { useGetUserName } from '@lp-root/src/components/obj.custom-hooks/user-info.hook';
import WebviewLayout from '@lp-root/src/components/org.layout/webview-layout.component';
import {
  buildPostAttemptedSolutionsRequest,
  PostAttemptedSolutionsRequest,
} from '@lp-root/src/data/request-utils/attempted-solutions.request';
import { PageProps } from '@lp-src/utils/local-types';
import { Body, Col, FontSize, Grid, H1, InputLabel, Row, Separator } from '@web/atomic';
import { ActivityIndicator } from '@web/atomic/legacy/atm.activity-indicator';
import { CheckboxField } from '@web/atomic/legacy/atm.checkbox';
import { TextAreaField } from '@web/atomic/legacy/atm.text-field';
import { TextShimmerBoxStyled } from '@web/atomic/legacy/mol.shimmer/shimmer.component.style';
import { ShimmerButton, ShimmerInput, ShimmerText } from '@web/atomic/legacy/mol.shimmer/shimmer.options';
import flashDispatcherService from '@web/atomic/legacy/obj.flash-wrapper/flash-dispatcher.service';
import { Form, FormData } from '@web/atomic/legacy/obj.form';
import { useCloseMessengerModalCallback } from '@web/atomic/obj.custom-hooks/close-messenger-modal.hook';
import { usePostUsingMessenger } from '@web/data/use-post-using-messenger.hook';
import { PostUrl } from '@web/data/vigilantes.datasource';
import { hasWindow } from '@web/utils/platform';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import QueryString from 'querystringify';
import React, { useCallback, useEffect } from 'react';

export enum SolutionAttemptTag {
  Melatonin = 'melatonina',
  SleepingMedicine = 'medicamento',
  SleepHygiene = 'higiene-do-sono',
  Alcohol = 'alcool',
  SleepRestriction = 'restricao',
}

export interface SolutionAttemptPageFormData {
  solutions: SolutionAttemptTag[];
  comment: string;
}

const SolutionAttemptPage: React.FunctionComponent<PageProps> = (props) => {
  const [user] = useGetUserName();
  const userName = user && user.first_name;

  return (
    <WebviewLayout>
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Tentativas de soluções',
          description: 'O que você já tentou?',
        }}
      />

      <Grid>
        <Row mt mb>
          <Col xs={12} sm={12} md={7} lg={7}>
            <H1>
              {userName || <TextShimmerBoxStyled height={FontSize.XLarge} width={'60px'} />}, o que você já tentou para melhorar sua
              insônia?
            </H1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={7} lg={7}>
            <SolutionAttemptForm userId={user && user.id} />
          </Col>
        </Row>
      </Grid>
    </WebviewLayout>
  );
};

// ------------------------------------------------------------------------------------------------------

interface ISolutionAttemptFormProps {
  userId: string;
}

interface SolutionAttemptFormQueryParams {
  hide_sleep_restriction?: string;
}

const SolutionAttemptForm: React.FunctionComponent<ISolutionAttemptFormProps> = (props) => {
  const [hideSleepRestriction, setHideSleepRestriction] = React.useState(false);
  useEffect(() => {
    const search: SolutionAttemptFormQueryParams = QueryString.parse(hasWindow() ? window.location.search : '');
    setHideSleepRestriction(toBoolean(search.hide_sleep_restriction));
  }, []);

  const [submit, { loading: submitLoading }] = useUpdateUserRequest();
  const handleSubmit = useCallback(
    async (formData: FormData<SolutionAttemptPageFormData>) => {
      if (Object.keys(formData.error).length !== 0) return;

      await submit(buildPostAttemptedSolutionsRequest(props.userId, formData.data));
    },
    [props.userId, submit]
  );

  const submitText = 'Enviar resposta';
  const checkboxShimmering = (
    <div>
      <ShimmerText height={'18px'} />
    </div>
  );
  return (
    <Form onSubmit={handleSubmit}>
      <Row mb center="xs">
        <Col xs>
          <Form.Field name="solutions" label={`Selecione quantas opções você quiser e clique no botão "${submitText}":`}>
            <Separator />
            {!props.userId ? (
              <Body>
                Carregando <ActivityIndicator />
              </Body>
            ) : null}
            {props.userId ? <CheckboxField id={SolutionAttemptTag.Melatonin}>Melatonina</CheckboxField> : checkboxShimmering}
            {props.userId ? (
              <CheckboxField id={SolutionAttemptTag.SleepingMedicine}>Medicamento para Dormir</CheckboxField>
            ) : (
              checkboxShimmering
            )}
            {props.userId ? <CheckboxField id={SolutionAttemptTag.SleepHygiene}>Higiene do Sono</CheckboxField> : checkboxShimmering}
            {props.userId ? <CheckboxField id={SolutionAttemptTag.Alcohol}>Álcool</CheckboxField> : checkboxShimmering}

            {!hideSleepRestriction && props.userId && (
              <CheckboxField id={SolutionAttemptTag.SleepRestriction}>Restrição do Sono</CheckboxField>
            )}
          </Form.Field>
          <Separator />
        </Col>
      </Row>

      <Row mb center="xs">
        <Col xs>
          <Form.Field name="comment">
            <InputLabel>
              {' '}
              <span role="img" aria-label="mão escrevendo">
                ✍️
              </span>{' '}
              Algum comentário a mais? (opcional)
            </InputLabel>
            {props.userId ? <TextAreaField /> : <ShimmerInput />}
          </Form.Field>
          <Separator />
        </Col>
      </Row>

      {props.userId ? (
        <Row mb>
          <Col xs={12} sm={12} md={4} lg={4}>
            <GatsbyButton type="submit" kind="primary" loading={submitLoading} expanded>
              {submitText}
            </GatsbyButton>
            <Separator />
          </Col>
        </Row>
      ) : (
        <ShimmerButton />
      )}
    </Form>
  );
};

// ------------------------------------------------------------------------------------------------------
const useUpdateUserRequest = () => {
  const { close } = useCloseMessengerModalCallback();
  const handleSuccess = useCallback(() => {
    flashDispatcherService.dispatchMessage('Resposta enviada com sucesso!', 'success');
    close();
  }, [close]);

  return usePostUsingMessenger<PostAttemptedSolutionsRequest>({ url: PostUrl.UpdateUser, onSuccess: handleSuccess });
};

export default SolutionAttemptPage;

export const query = graphql`
  query SolutionAttemptQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
