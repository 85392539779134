import { SolutionAttemptPageFormData, SolutionAttemptTag } from '@lp-root/src/pages/chatfuel/solucoes-tentadas';

export interface PostAttemptedSolutionsRequest {
  // user id (messenger id)
  id: string;
  tried_alcohol?: boolean;
  tried_medicine?: boolean;
  tried_melatonin?: boolean;
  tried_other?: string;
  tried_restriction?: boolean;
  tried_sleep_hygiene?: boolean;
}

export const buildPostAttemptedSolutionsRequest = (userId: string, data: SolutionAttemptPageFormData): PostAttemptedSolutionsRequest => ({
  id: userId,
  tried_alcohol: data.solutions && data.solutions.includes(SolutionAttemptTag.Alcohol),
  tried_medicine: data.solutions && data.solutions.includes(SolutionAttemptTag.SleepingMedicine),
  tried_melatonin: data.solutions && data.solutions.includes(SolutionAttemptTag.Melatonin),
  tried_other: data.comment,
  tried_restriction: data.solutions && data.solutions.includes(SolutionAttemptTag.SleepRestriction),
  tried_sleep_hygiene: data.solutions && data.solutions.includes(SolutionAttemptTag.SleepHygiene),
});
